
.nineDiv {
  .nineContent {
    float: left;
    border: 2px solid #333;
    line-height: 16px;
    .contentTitle {
      font-size: 18px;
      font-weight: bold;
      color: #e73535e5;
      width: 100%;
      height: 20px;
      text-align: center;
      padding-top: 10px;
      margin-bottom: 10px;
    }

    .v-input__slot:before {
      border-color: none !important;
    }
    .triz-textarea textarea {
      font-size: 16px;
      width: 100%;
      margin-top: 5px;
      line-height: 16px;
      height: 107px !important;
      border: none;
      resize: none;
      outline: none;
      text-align: center;
      overflow: hidden;
    }
    .triz-textarea {
      font-size: 16px;
      width: 100%;
      margin-top: 5px;
      line-height: 1px;
      max-height: calc(100% - 24px) !important;
      border: none;
      resize: none;
      outline: none;
      text-align: center;
      overflow: hidden;
    }
  }
  .nineContent2 {
    float: left;
    border: 2px solid #333;
    line-height: 16px;
    .contentTitle {
      font-size: 18px;
      font-weight: bold;
      color: #e73535e5;
      width: 100%;
      height: 20px;
      text-align: center;
      padding-top: 10px;
      margin-bottom: 10px;
    }
    .v-input__slot:before {
      border-color: none !important;
    }
    .triz-textarea textarea {
      font-size: 16px;
      width: 100%;
      margin-top: 5px;
      line-height: 16px;
      height: 119px !important;
      border: none;
      resize: none;
      outline: none;
      text-align: center;
      overflow: hidden;
    }
    .triz-textarea {
      font-size: 16px;
      width: 100%;
      margin-top: 5px;
      line-height: 1px;
      max-height: calc(100% - 24px) !important;
      border: none;
      resize: none;
      outline: none;
      text-align: center;
      overflow: hidden;
    }
  }
  .nineline {
    float: left;
    display: flex;
    flex-flow: row wrap;
    align-content: center;
  }
  .leftLine {
    float: left;
    width: 0;
    height: 0;
    border-width: 12px;
    border-style: solid;
    border-color: transparent #0099cc transparent transparent;
  }
  .leftxLine {
    float: right;
    width: calc(100% - 24px);
    position: relative;
    top: 6px;
    height: 10px;
    background: #0099cc;
  }
  .rightLine {
    float: right;
    width: 0;
    height: 0;
    border-width: 12px;
    border-style: solid;
    border-color: transparent transparent transparent #0099cc;
  }
  .rightxLine {
    float: left;
    width: calc(100% - 24px);
    position: relative;
    top: 6px;
    height: 10px;
    background: #0099cc;
  }
  .ninetbline {
    width: 30px;
    margin: 0 auto;
    .topLine {
      width: 0;
      height: 0;
      border-width: 14px;
      border-style: solid;
      border-color: transparent transparent #0099cc transparent;
    }
    .topxLine {
      height: 16px;
      position: relative;
      left: 0px;
      width: 15px;
      background: #0099cc;
    }
    .bottomLine {
      width: 0;
      height: 0;
      border-width: 14px;
      border-style: solid;
      border-color: #0099cc transparent transparent transparent;
    }
    .bottomxLine {
      height: 16px;
      position: relative;
      left: 0px;
      width: 15px;
      background: #0099cc;
    }
  }
}
