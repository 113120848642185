// 定义媒体查询的断点
$breakpoint-small: 480px;
$breakpoint-medium: 768px;
$breakpoint-large: 1100px;

.zdy_bjt {
	background-image: url('../../assets/bjt1.png');
	background-size: 110%;
	background-position: -15px 550px;
}



.c_v_window{
	margin-top: 10px 0px 10px 0px;
}
.c_v_window_i{
}
.w_v_card{
	
}
.w_v_c_div{
	overflow: auto;
	scrollbar-width: thin;
	scrollbar-color: #9d9d9d transparent;
}

.w_v_c_div::-webkit-scrollbar {
  width: 0px;
}

.w_v_c_div::-webkit-scrollbar-thumb {
  background-color: #9d9d9d;
  border-radius: 0px;
}

.w_v_c_div::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 0px;
}

.v-window__prev, .v-window__next {
    background: #fff;
}
.font-size-30{
	@media (min-width: $breakpoint-small) {
		font-size: 10px;
	}
	@media (min-width: $breakpoint-medium) {
		font-size: 20px;
	}
	@media (min-width: $breakpoint-large) {
		font-size: 30px;
	}
}
.font-size-26{
	@media (min-width: $breakpoint-small) {
		font-size: 6px;
	}
	@media (min-width: $breakpoint-medium) {
		font-size: 16px;
	}
	@media (min-width: $breakpoint-large) {
		font-size: 26px;
	}
}
.font-size-24{
	@media (min-width: $breakpoint-small) {
		font-size: 4px;
	}
	@media (min-width: $breakpoint-medium) {
		font-size: 14px;
	}
	@media (min-width: $breakpoint-large) {
		font-size: 24px;
	}
}
p{
	text-indent: 2em;
	@media (min-width: $breakpoint-small) {
		line-height: 25px;
	}
	@media (min-width: $breakpoint-medium) {
		line-height: 30px;
	}
	@media (min-width: $breakpoint-large) {
		line-height: 50px;
	}
}
.row {
  margin: 0;
}
.Triz_Select {
  width: 165px;
  font-size: 24px;
  line-height: 48px;
    margin-left: 10px;
  .v-input__icon {
    display: none;
  }
  .v-input__slot {
    box-shadow: none !important;
    min-height: 38px !important;
    margin-top: 6px !important;
    fieldset{
      border: 2px solid #1976d2;
      border-radius: 0;
    }
  }
  .v-select__selections {
    min-width: 80px;
    min-height: 40px;
    padding: 0!important;
    input::-webkit-input-placeholder{
      font-size: 24px;
      position: relative;
      top: 4px;
      color: #0055ff;
    }
  }
  .v-input .v-label {
    font-size: 24px;
    color: #000;
    height: 40px;
  }

  .v-select__slot {
    .v-select__selections {
      .v-select__selection--comma {
        margin: 0;
        height: 40px;
        line-height: 40px;
        font-weight: 100;
        font-size: 24px;
      }
    }
  }
}
.Triz_Select-table {
  width: 165px;
  font-size: 18px;
  .v-select__selection{
	  width: 100%;
	  text-align: center;
	  
  }
  input {
   text-align: center;
  }
  input::-webkit-input-placeholder {
    color: #3f81c1;
  }
  
}
.Triz_checkbox {
  .v-input__slot {
    justify-content: center;
    align-items: center;
  }
}
.special_select {
  .v-text-field input {
    width: 30px;
  }
}
.special_p p {
  text-indent: 0;
}
.AltshullerXY:hover {
  -webkit-transform: scale(3, 3);
}
.Triz_textarea {
  .v-text-field__details {
    display: none;
  }
  .v-input__slot {
    margin-bottom: 0;
  }
  .v-input__slot:before,
  .v-input__slot:after {
    display: none;
  }
  .v-text-field {
    padding-top: 0;
  }
  .v-input textarea {
    font-size: 18px;
	text-align: center;
  }
  .v-input textarea::-webkit-input-placeholder {
    color: #3f81c1;
  }
}
.v-list-item__title{
	text-align: center;
}
.Triz_textarea_system {
  .v-text-field__details {
    display: none;
  }
  .v-input__slot {
    margin-bottom: 0;
  }
  .v-text-field {
    padding-top: 0;
  }
  .v-input textarea {
    font-size: 18px;
    text-indent: 10px;
  }
  .v-input textarea::-webkit-input-placeholder {
    color: #333;
  }
}
.v-menu__content{
  background: #fff;
  .v-list-item--link:hover{
    background: rgb(24, 103, 192)!important;
    color: #fff!important;
  }
}
.v-bottom-sheet.v-dialog.v-bottom-sheet--inset{
  width: auto;
}
.nieFX{}
.FunctionalAnalysis {
  .v-text-field.v-text-field--enclosed .v-text-field__details {
    margin-bottom: 0;
    display: none;
  }
  .v-input__control {
    padding: 7px 0;
  }
  .v-input__append-inner {
    margin-top: 5px !important;
  }
  // .v-list-item {
  //   padding: 0 10px;
  // }
  .v-list {
    padding: 12px 0;
  }
}
table {
  width: 100%;
  font-size: 18px;
  border-top: 1px solid #999;
  border-left: 1px solid #999;
  border-spacing: 0;
  /*去掉单元格间隙*/
}

table th {
  border-bottom: 1px solid #999;
  border-right: 1px solid #999;
}

table td {
  border-top: 1px solid #999;
  border-bottom: 1px solid #999;
  border-right: 1px solid #999;
}
table {
  tr {
    min-height: 50px;
    td {
      border-top: none;
    }
  }
  tr:nth-child(1) {
    min-height: 60px;
    td {
      border-top: 1px solid #999;
    }
  }
}

.remarkable {
  outline: 2px solid #d10213;
}
