
.principle_size {
  margin-top: 0 !important;
  p {
    font-size: 18px !important;
    text-indent: 30px !important;
    margin-bottom: 0px !important;
    line-height: 20px !important;
  }
}
